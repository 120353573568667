import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImgDiscuss from "../../imgs/transferir.jpg";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        paddingLeft: 10,
        paddingRight: 10,


    },
    img: {

        borderRadius: 10,
        height: 60,
        alignItems: 'center',

    },



    paper: {
        maxWidth: 400,
        margin: `${theme.spacing(0)}px auto`,
        padding: 7,
        boxShadow: 'none',

    },

    timeFontSize: {
        fontSize: 12,
    },

}));


function Highlights() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>

                    <Grid item xs>

                        <Typography>Artista com fome comeu banana de 120 mil dólares de Maurizio Cattelan</Typography>
                        <Typography className={classes.timeFontSize}>há x semanas</Typography>

                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>

                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography> <Typography>Artista com fome comeu banana de 120 mil dólares de Maurizio Cattelan</Typography></Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ana Melo respondeu ao seu comentário "Não se trata de precipitações, trata-se de um gove..."</Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ana Melo respondeu ao seu comentário "Não se trata de precipitações, trata-se de um gove..."</Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ana Melo respondeu ao seu comentário "Não se trata de precipitações, trata-se de um gove..."</Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ana Melo respondeu ao seu comentário "Não se trata de precipitações, trata-se de um gove..."</Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
                <hr/>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item>
                        <img className={classes.img} src={ImgDiscuss}/>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ana Melo respondeu ao seu comentário "Não se trata de precipitações, trata-se de um gove..."</Typography>
                        <Typography className={classes.timeFontSize}>há x dias</Typography>
                    </Grid>
                </Grid>
            </Paper>



        </div>
    );
}

export default Highlights;