import React from 'react';
// import '../css/main.min.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SettingsIcon from '@material-ui/icons/Settings';


function MenuBarProfile() {


    return (

        <AppBar className="menuColor" position="static">
            <Toolbar>

                <ArrowBackRoundedIcon className="arrowColor"/>


                <Typography variant="h6" className="menuTitle">  Perfil
                </Typography>

                <SettingsIcon className="settingsColor"/>
            </Toolbar>
        </AppBar>


    );
}

export default MenuBarProfile;