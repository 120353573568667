import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import imgsTestes from '../../imgs/transferir.jpg';
import {Card, CardActions, CardContent, makeStyles, Tooltip} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',

        '& > *': {
            margin: 3,
        }
    },
    card: {
        display: 'flex',
        minWidth: 275,
        backgroundColor: 'black',
        color: 'white',
        borderRadius: 7
    },
    title: {
        fontSize: 16,
        color: '#D10019',
    },
    pos: {
        marginBottom: 12,
    },
    cover: {
        width: 120,
        height: 165,
        zIndex: 500
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

function FeedCinema() {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid container className="p10Top">
                <Grid item container xs={6} alignContent="flex-start">
                    <Typography variant="h6">Cinema</Typography>
                </Grid>
                <Grid xs={6} item container justify="flex-end" alignItems="center">
                    <Button>
                        <Typography variant="caption" className="underlineRed">ver tudo</Typography>
                    </Button>
                </Grid>
            </Grid>
            <Card className={classes.card} variant="outlined">
                <CardContent>
                    <Typography className='redTitles' gutterBottom>
                        Joker
                    </Typography>
                    <Typography variant="h5" component="h2">
                        O filme que é tudo menos uma piada
                    </Typography>
                    <br/>
                    <div className={classes.root}>
                        <Avatar alt="Remy Sharp" src={imgsTestes} className={classes.small}/>
                        <Avatar alt="Travis Howard" src={imgsTestes} className={classes.small}/>
                        <Avatar alt="Cindy Baker" src={imgsTestes} className={classes.small}/>
                        <Typography title="Foo • Bar • Baz">
                            59 críticas
                        </Typography>
                    </div>
                </CardContent>
                <CardContent>
                    <CardMedia
                        className={classes.cover}
                        image={require ('../../imgs/images.jpg')}
                        title="Live from space album cover"/>
                </CardContent>
            </Card>
        </Grid>
    )

}

export default FeedCinema;