import React, {useEffect, useState} from 'react';
import './css/main.min.css';
import ApolloClient from 'apollo-boost';
import MessageConversation from './components/Messages/MessageConversation';
import MessageList from './components/Messages/MessageList';
import {ApolloProvider} from 'react-apollo'
import Topic from "./components/Topic";
import MenuBar from "./components/MenuBar";
import FirstSelect from './components/home/FirstSelect';
import BottomMenu from "./components/BottomMenu";
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import ProfilePage from './components/Profile/ProfilePage';
import Home from "./components/home/Home";
import MainScreen from "./components/MainScreen";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import TopicsList from "./components/TopicsList";
import SignInSide from "./components/SignInSide";
import SignUp from "./components/SignUp";
import {BrowserRouter, useHistory, Redirect} from "react-router-dom";
import CreateDiscussion from "./components/discussion/CreateDiscussion";
import Hidden from "@material-ui/core/Hidden/Hidden";

const Switch = require("react-router-dom").Switch;
const Route = require("react-router-dom").Route;


const client = new ApolloClient({
    uri: 'http://localhost:4000/graphql'
});

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            textPrimary: {
                color: '#fff',
            }
        },
    },
});


function App() {

    const [user, setUser] = useState(true);
    // const history = useHistory();

    useEffect(() => {
        if (!user) {
            console.log(user)
        }
    }, []);


    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    {!user &&
                    <Redirect to='/login'/>
                    }
                    <Grid container justify="center">
                        <MenuBar/>
                        <Grid item xs={12} md={10} sm={10} lg={8}>
                            <div id="main" style={{marginBottom: 60}}>
                                <Switch>
                                    {/*<TopicsList/>*/}
                                    <Route exact path='/' component={MainScreen}/>
                                    <Route exact path='/create' component={CreateDiscussion}/>
                                    <Route exact path='/profile' component={ProfilePage}/>
                                    <Route exact path='/messages' component={MessageList}/>
                                    {!user &&
                                    <Route exact path='/login' component={CreateDiscussion}/>
                                    }
                                    {/*<Route path='/profile/:id' component={FirstSelect}/>*/}
                                </Switch>
                            </div>
                            <Hidden mdUp>
                                <BottomMenu/>
                            </Hidden>

                        </Grid>
                    </Grid>
                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
