import React from 'react';
import {useMutation, useQuery} from '@apollo/react-hooks'
import {deleteTopicMutation, getTopics, updateTopicMutation} from "../queries/queries";
import AddComment from "./AddComment";
import AddTopic from "./AddTopic";

function TopicsList(props) {
    const {loading, error, data} = useQuery(getTopics);
    const [updateTopic] = useMutation(updateTopicMutation);
    const [deleteTopic] = useMutation(deleteTopicMutation);
    const displayDiscussions = () => {

        if (loading) return <p>Loading ...</p>;
        return data ? data.getTopics.map((key, value) => {
            let input;

            return <div>
                <form id={key} onSubmit={e => {
                            e.preventDefault();
                            updateTopic({ variables: { id: value._id, body: input.value } });

                            input.value = '';
                        }}>
                         <li>{value.body}</li>
                        <input
                            ref={node => {
                                input = node;
                        }}/>
                    <button>EDIT</button>
                </form>
                <button onClick={() => removeTopic(value)}>DELETE</button>
            </div>
        }) : [];
    };

    const removeTopic = (dataToRemove) => {
            deleteTopic({
                variables: {
                    _id: dataToRemove._id
                },
                refetchQueries:[
                    {query: getTopics}
                ]
            });
    };

    return(
        <div>
            <ul>
                <AddTopic/>
                {displayDiscussions()}
            </ul>
        </div>
    )
}

export default TopicsList;
