import React, {useState} from 'react';
import MenuBarProfile from "./MenuBarProfile";
import ImgProfile from "../../imgs/mlemtest.jpg";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import Recents from "./Recents";
import Highlights from "./Highlights";


const styles = {
    root: {
        flexGrow: 1,
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        boxShadow: 'none',
    },

    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 600,
        boxShadow: 'none',
        paddingLeft: 10,
    },

    icons:{

      paddingLeft: 10,

    },

    paper2: {
                    
        maxWidth: 50,
        boxShadow: 'none',
        fontSize: 15,

    },


    image: {
        width: 130,
        height: 130,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: '50%',
    },
}));


function ProfilePage() {

    const [value, setValue] = useState(0)

    const handleChange = (event, value) => {
        setValue(value)
    };

    const classes = useStyles();



    return (


        <div className={classes.root}>

            <MenuBarProfile/>

            <Paper className={classes.paper}>
                <Grid container justify='center'>

                    <Grid item style={{alignItems: 'center', flexWrap: 'nowrap'}} container spacing={2} xs={10}>
                        <Grid item>
                            <ButtonBase className={classes.image}>
                                <img className={classes.img} src={ImgProfile}/>
                            </ButtonBase>
                        </Grid>
                        <Grid className={classes.grid} item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="h6" className="menuTitle">
                                        João Oliveira
                                    </Typography>
                                </Grid>

                                <Grid className={classes.icons} container spacing={0}>
                                    <Grid item xs>
                                        <ThumbUpAltOutlinedIcon className="profileIcon"/>
                                        <Paper className={classes.paper2}>345</Paper>
                                    </Grid>
                                    <Grid item xs>
                                        <ExitToAppRoundedIcon className="profileIconUp"/>
                                        <Paper className={classes.paper2}>234</Paper>
                                    </Grid>
                                    <Grid item xs>
                                        <ChatOutlinedIcon className="profileIcon"/>
                                        <Paper className={classes.paper2}>124</Paper>
                                    </Grid>
                                </Grid>

                            </Grid>


                        </Grid>
                    </Grid>

                    <Grid item xs={1}/>


                </Grid>
            </Paper>

            <Grid container>

             <Paper className={classes.root}>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered

                   /* variant="fullWidth"     */
                >
                    <Tab label="Atividade Recente"/>
                    <Tab label="Destaques" />
                  
                </Tabs>

                 {value === 0 && <Recents/> }
                 {value === 1 && <Highlights/> }

             </Paper>

            </Grid>


        </div>


    );


}


export default ProfilePage;


