import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContent from "./TabContent";
import BottomMenu from "../BottomMenu";


function Home() {

    const [categories, setCategories] = useState(['Início', 'Desporto', 'Música', 'Mundo', 'Design', 'Gastronomia', 'Cultura', 'Cinema', 'Política', 'Moda', 'Sociedade']);
    const [value, setValue] = useState(0);
    const [tab, setTab] = useState([]);

    const handleChange = (event, value) => {
        setValue(value)
    };

    // useEffect(() => {
    //     const tabEdit = categories.map((category, key) => (
    //         <Tab label={category} key={key}/>
    //     ));
    //     setTab(tabEdit);
    // }, [categories]);

    return (
        <div className="mainPadding">
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {/*{tab}*/}
                {categories.map((category, key) => (
                    <Tab label={category} key={key}/>
                ))}
            </Tabs>
            <TabContent value={value}/>
        </div>
    )
}

export default Home;