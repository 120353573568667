import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import profileImg from '../../imgs/profile.jpg';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addTag: {
        backgroundColor: 'white !important',
        border: '1px solid black',
        outlineColor: 'black',
        color: 'black',
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: '5px',
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

function CreateDiscussion() {
    const classes = useStyles();
    const [age, setAge] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = event => {
        setAge(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Grid className={classes.root}>
            <Grid>
                <Paper className={classes.paper}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Avatar src={profileImg}></Avatar>
                        </Grid>
                        <Grid item style={{paddingTop: '1.5vh'}}>
                            <Typography variant="h5" noWrap>Ana Júlia</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <TextField
                        id="outlined-textarea"
                        placeholder="Escreva sobre o assunto que pretende discutir"
                        multiline
                        style={{width: '100%'}}
                    />
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Ligar a uma notícia</Typography>
                    <Typography color="textSecondary" variant="caption">Ligue a discussão a uma notícia do Público relacionada com o seu comentário</Typography>
                    <div style={{display: 'flex'}}>
                        <LinkIcon color="error" style={{paddingTop: '1.5%'}}/><Typography color="error" variant="h6">Escreva aqui o link</Typography>
                    </div>
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Publicar em</Typography>
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            value={age}
                            onChange={handleChange}
                        >
                            <MenuItem value={'Desporto'}>Desporto</MenuItem>
                            <MenuItem value={'Política'}>Política</MenuItem>
                            <MenuItem value={'Mundo'}>Mundo</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Tags</Typography>
                    <Avatar className={classes.addTag}>
                        +
                    </Avatar>
                </Paper>
                <Paper className={classes.paper}>
                    <ColorButton variant="contained" color="primary" className="redBackgrounds">
                        Criar discussão
                    </ColorButton>
                </Paper>
            </Grid>
        </Grid>
    )

}

export default CreateDiscussion;