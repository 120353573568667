import React from 'react';
import Grid from '@material-ui/core/Grid'
import BottomMenu from "../BottomMenu";
import Feed from './Feed';
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden';


function TabContent(props) {
    return (
        <div>
            <Grid container className="mainPadding">
                {props.value === 0 ? <Feed/> :
                    <Typography>Ola</Typography>
                }
            </Grid>

        </div>
    )
}

export default TabContent;