import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from "@material-ui/core/Button/Button";
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';

function FeedLatest(props) {
    return (
        <Grid container className="p10Top">
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant="subtitle1" className="redTitles">{props.category}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption" className="grayTitles">{props.topic}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} onClick={() => console.log("Fui para a discussão em específico")}>
                <Grid item>
                    <AccountCircle/>
                </Grid>
                <Grid item>
                    <Typography>Raquel Silva</Typography>
                </Grid>
                <Grid item>
                    <Typography className="grayTitles" variant="caption">há 1 dia</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography noWrap>Lorem ipsum
                        dolor sit amet, consectetur adipiscing elit. Cras quis aliquet quam. Ut risus lacus,
                        sagittis vehicula ipsum eu, hendrerit euismod arcu. Donec aliquam vestibulum
                        viverra. </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item>
                    <Button style={{color: '#787777'}}>
                        <ThumbUpAltOutlinedIcon fontSize="small"/>43
                    </Button>
                </Grid>
                <Grid item>
                    <Button style={{color: '#787777'}}>
                        <ExitToAppRoundedIcon fontSize="small"/>324
                    </Button>
                </Grid>
                <Grid item>
                    <Button style={{color: '#787777'}}>
                        <ChatOutlinedIcon fontSize="small"/>157
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FeedLatest;