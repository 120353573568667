import React from 'react';
// import '../css/main.min.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import {Grid} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {useHistory} from 'react-router-dom';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
}));

function MenuBar() {
    const classes = useStyles();
    const history = useHistory();

    const [auth, setAuth] = React.useState(true);

    const handleMenu = () => {
        history.push("/profile")
    };

    return (
        <AppBar className="menuColor" position="static">
            <Toolbar>
                <Grid container justify="center">
                    <Grid container item xs={12} md={10} sm={10} lg={8} alignItems="center">
                        <Typography variant="h6" className="menuTitle" onClick={() => {history.push("/");}}>
                            ÁGORA
                        </Typography>
                        <Hidden smDown>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon/>
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{'aria-label': 'search'}}
                                />
                            </div>
                            <div>
                                <IconButton color="inherit">
                                    <ForumOutlinedIcon onClick={() => {history.push("/messages");}}/>
                                </IconButton>
                                <IconButton color="inherit">
                                    <NotificationsNoneOutlinedIcon onClick={() => {history.push("/profile");}}/>
                                </IconButton>
                            </div>
                        </Hidden>
                        {auth ? (
                                <div>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle/>
                                    </IconButton>
                                </div>
                            ) :
                            <div>
                                <Typography variant="h6" className="menuTitle">
                                    LOGIN
                                </Typography>
                            </div>}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default MenuBar;