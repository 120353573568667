import React from 'react';
import {makeStyles} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowY: 'hidden',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

function FeedMayLike() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Chip className="tags" label="#cinema"/>
            <Chip className="tags" label="#tdi"/>
            <Chip className="category" label="Tecnologia"/>
            <Chip className="tags" label="#inovação"/>
            <Chip className="tags" label="#inovação"/>
            <Chip className="tags" label="#inovação"/>
        </div>
    )
}

export default FeedMayLike;