import React, {useState} from 'react';
import {useQuery, useMutation} from "@apollo/react-hooks";
import {Grid} from '@material-ui/core'
import {getTopic} from "../queries/queries";
import BuildComment from "../utilComponents/BuildComment";

function Topic() {
    const [dataComments, setDataComments] = useState([]);
    const {loading, error, data} = useQuery(getTopic, {
        variables: {
            id: "5e1a024b29b710560c6ab67b"
        }
    });


    // const writeComments = () => {
    //     data.topic.comments.map((data, index) => {
    //
    //         if (data.children.length !== 0) {
    //             data.children.map((dataChildren, index) => {
    //                 getComments(dataChildren);
    //             });
    //         }
    //     })
    // };
    //
    // const getComments = (data) => {
    //     let dataToComments = dataComments;
    //     dataToComments.push(<BuildComment data={data}/>);
    //     setDataComments(dataToComments);
    //     if(data.children !== 0){
    //         data.children.map((dataChildren, index) => {
    //             getComments(dataChildren);
    //         });
    //     }
    // };

    // if (!loading) {
    //     writeComments();
    // }
    if (error) return console.log(error);

    console.log(data);
    return (
        <Grid container justify='start' style={{padding: 0}}>
            {/*{dataComments.length !== 0 &&*/}
            {/*    <div>*/}
            {/*        {{dataComments}}*/}
            {/*    </div>*/}
            {/*}*/}
        </Grid>
    )
}

export default Topic;