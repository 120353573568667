import React from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import SwipeableViews from "react-swipeable-views";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import FeedLatest from "./FeedLatest";
import FeedCinema from "./FeedCinema";
import FeedCommunity from "./FeedCommunity";
import FeedMayLike from "./FeedMayLike";
import imgsTestes from '../../imgs/transferir.jpg';
import Hidden from '@material-ui/core/Hidden';

const styles = {
    root: {
        paddingRight: '40px',
        paddingLeft: '20px',
    },
    feedCommunity: {
        paddingRight: '40px',
        paddingLeft: '20px',
    },
    slideContainer: {
        paddingRight: '5px',
    },
    feedCommunitySlideContainer: {
        paddingRight: '10px',
    },
    slide: {
        minHeight: 200,
        color: '#fff',
        backgroundImage: `url(${imgsTestes})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    desktopCardContainer: {
      paddingTop: 10
    },
    desktopCard: {
        backgroundImage: `url(${imgsTestes})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: 250,
        color: '#fff',
        marginLeft: 10
    }
};

function Feed() {

    return (
        <Grid container>
            <Grid container>
                <Hidden mdUp>
                    <SwipeableViews style={styles.root} slideStyle={styles.slideContainer}>
                        <Card style={styles.slide}>
                            <CardContent>
                                <Typography>Categoria</Typography>
                                <Typography noWrap>TÍTULO DA NOTÍCIA COM MAIS INFORMAÇÃO QUE DEVIA</Typography>
                            </CardContent>
                            <CardActions style={{position: 'absolute', bottom: 0}}>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>44
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>52
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>200
                                </Button>
                            </CardActions>
                        </Card>
                        <Card style={styles.slide}>
                            <CardContent>
                                <Typography>Categoria</Typography>
                                <Typography>TÍTULO DA NOTÍCIA COM MAIS INFORMAÇÃO QUE DEVIA</Typography>
                            </CardContent>
                            <CardActions style={{position: 'absolute', bottom: 0}}>
                                <Button size="small" color="primary">
                                    44
                                </Button>
                                <Button size="small" color="primary">
                                    52
                                </Button>
                                <Button size="small" color="primary">
                                    200
                                </Button>
                            </CardActions>
                        </Card>
                    </SwipeableViews>
                </Hidden>
                <Hidden smDown>
                    <Grid container justify="center" style={styles.desktopCardContainer}>
                        <Card style={styles.desktopCard}>
                            <CardContent>
                                <Typography>Categoria</Typography>
                                <Typography style={{paddingTop: 100}}>TÍTULO DA NOTÍCIA COM MAIS INFORMAÇÃO QUE
                                    DEVIA</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>44
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>52
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>200
                                </Button>
                            </CardActions>
                        </Card>
                        <Card style={styles.desktopCard}>
                            <CardContent>
                                <Typography>Categoria</Typography>
                                <Typography style={{paddingTop: 100}}>TÍTULO DA NOTÍCIA COM MAIS INFORMAÇÃO QUE
                                    DEVIA</Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>44
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>52
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatOutlinedIcon/>200
                                </Button>
                            </CardActions>
                        </Card>
                        <Hidden mdDown>
                            <Card style={styles.desktopCard}>
                                <CardContent>
                                    <Typography>Categoria</Typography>
                                    <Typography style={{paddingTop: 100}}>TÍTULO DA NOTÍCIA COM MAIS INFORMAÇÃO QUE
                                        DEVIA</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color="primary">
                                        <ChatOutlinedIcon/>44
                                    </Button>
                                    <Button size="small" color="primary">
                                        <ChatOutlinedIcon/>52
                                    </Button>
                                    <Button size="small" color="primary">
                                        <ChatOutlinedIcon/>200
                                    </Button>
                                </CardActions>
                            </Card>
                        </Hidden>
                    </Grid>
                </Hidden>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Últimas</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedCinema/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Últimas</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Comunidades</Typography>
                </Grid>
                <FeedCommunity category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Poderá gostar de</Typography>
                </Grid>
                <FeedMayLike category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Sociedade</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
            </Grid>
        </Grid>
    )

}

export default Feed;