import React, {useState} from 'react';
import MenuBar from "./MenuBar";
import Home from "./home/Home";
import NoPreferences from "./home/NoPreferences";
import FirstSelect from "./home/FirstSelect";
import {Grid} from '@material-ui/core';


function MainScreen(props) {
    const [preferencesDone, setPreferencesDone] = useState(true);
    return (
        <div>
            {/*<MenuBar/>*/}
            {
                !preferencesDone ? <FirstSelect/> : <Home/>
            }
            {/*<BottomMenu/>*/}
        </div>
    );
}

export default MainScreen;