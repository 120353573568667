import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import imgsTestes from '../../imgs/transferir.jpg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function FirstSelect() {

    const categories = ['Desporto', 'Música', 'Mundo', 'Design', 'Gastronomia', 'Cultura', 'Cinema', 'Política', 'Moda', 'Sociedade'];
    const [selectedCat, setSelectedCat] = useState([]);

    function toggleCat(name) {

        setSelectedCat(prevState => {
            if (prevState.includes(name)) {
                return prevState.filter(cat => cat !== name)
            }

            return [...prevState, name]
        })
    }

    return (
        <div id="welcomeCategories">
            <Grid className="mainPadding" container>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>Em que está interessado?</Typography>
                </Grid>
                <Grid item xs={12} style={{marginBottom: 10, marginTop: 10}}>
                    <Typography variant="subtitle1">Utilizaremos as suas preferências para mostrar conteúdo
                        personalizado</Typography>
                </Grid>
                <Grid container spacing={3} className="mainPadding">
                    {categories.map((category, key) =>
                        <Grid item xs={6} md={2} id={key}>
                            <Card className="welcomeCategories" style={{
                                backgroundImage: 'url(' + imgsTestes + ')',
                            }} onClick={() => toggleCat(category)}>
                                <CardContent>
                                    <CardActions disableSpacing style={{position: 'relative', top: '50%', color: 'white'}}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={selectedCat.includes(category)}
                                                                   value={category}/>}
                                                label={category}
                                            />
                                        </FormGroup>
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid container xs={12} className="continueButton">
                <Button variant="contained" disabled={selectedCat.length <= 0} color="secondary">Continuar</Button>
            </Grid>

        </div>
    )
}

export default FirstSelect;