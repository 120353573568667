import {gql} from 'apollo-boost';

/*const getAuthorsQuery = gql`
    {
        authors{
            name
            id
        }
    }
`;

const getBooksQuery = gql`
    {
        books{
            name
            id
        }
    }
`;

const addBookMutation = gql`
    mutation($name:String!, $genre: String!, $authorId: ID!){
        addBook(name:$name, genre:$genre, authorId:$authorId){
            name
            id
        }
    }
`;*/

// CRUD TOPICS
const addTopicMutation = gql`
    mutation($user:ID!, $title:String!, $body:String!, $category:String!, $tags:[TagArray]){
        addTopic(user:$user, title:$title, body:$body, category:$category, tags:$tags){
            user
            title
            body
            source
            metaImage
            category
            tags {
                id
                name
            }
            upvotes
            main
            community
        }
    }
`;

const updateTopicMutation = gql`
    mutation($body:String, $id: ID!){
        updateTopic(body:$body, _id:$id){
            _id
            body
        }
    }
`;

const deleteTopicMutation = gql`
    mutation($_id: ID!){
        deleteTopic(_id:$_id){
            _id
        }
    }
`;

const getTopics = gql`
    {
        getTopics{
            body
            _id
        }
    }
`;

const getTopic = gql`
    query($id: ID!){
        topic(_id: $id){
            body
            user
            comments {
                id
                parent
                upvotes
                body
                created_at
                edited_at
                deleted_at
                children {
                    id
                    parent
                    upvotes
                    body
                    created_at
                    edited_at
                    deleted_at
                    children {
                        id
                        parent
                        upvotes
                        body
                        created_at
                        edited_at
                        deleted_at
                        children {
                            id
                            parent
                            upvotes
                            body
                            created_at
                            edited_at
                            deleted_at
                            children {
                                id
                                parent
                                upvotes
                                body
                                created_at
                                edited_at
                                deleted_at
                            }
                        }
                    }
                }
            } 
        }
    }
`;

// CRUD COMMENT

const getComment = gql`
    {
        id
        parent
        upvotes
        body
        created_at
        edited_at
        deleted_at
        children
    }
`;

const addCommentMutation = gql`
    mutation($body:String!, $topic: ID!){
        addComment(body:$body, topic:$topic){
            topic
            body
        }
    }
`;

const updateCommentMutation = gql`
    mutation($body:String!, $topic: ID!){
        updateComment(body:$body, topic:$topic){
            topic
            parent
            upvotes
            body
            created_at
            edited_at
            deleted_at
            children
        }
    }
`;

const deleteCommentMutation = gql`
    mutation($body:String!, $topic: ID!){
        deleteComment(body:$body, topic:$topic){
            topic
            parent
            upvotes
            body
            created_at
            edited_at
            deleted_at
            children
        }
    }
`;

export {
    /*getAuthorsQuery,
    getBooksQuery,
    addBookMutation,*/
    getTopics,
    addTopicMutation,
    updateTopicMutation,
    deleteTopicMutation,
    getTopic,
    getComment,
    addCommentMutation,
    updateCommentMutation,
    deleteCommentMutation,
}