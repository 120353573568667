import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import ArrowBackRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography/Typography";
import React from "react";

function MessagesBar() {


    return (

        <AppBar className="menuColor" position="static">
            <Toolbar>


                <Typography variant="h6" className="menuTitle">  Mensagens
                </Typography>

            </Toolbar>
        </AppBar>


    );
}

export default MessagesBar;