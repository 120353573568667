import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Row from '@material-ui/core/TableRow';
import { ThemeProvider, Message, MessageList, MessageMedia, MessageTitle, MessageButtons, MessageButton, MessageGroup, MessageText, TextComposer, SendButton, TextInput, IconButton, AddIcon, } from '@livechat/ui-kit';



function MessageConversation() {


    return (

        <ThemeProvider>


    <MessageList style={{ maxWidth: '100%', padding: '1.5em' }}>
        <div>
            <MessageGroup
                avatar="https://livechat.s3.amazonaws.com/default/avatars/male_8.jpg"
                onlyFirstWithMeta >
                <Message date="21:37">
                    <MessageText style={{backgroundColor: 'lightgray', borderRadius: '10px'}}>
                        The fastest way to help your customers - start chatting with visitors
                    </MessageText>
                </Message>
                <Message date="21:37">
                    <MessageText style={{backgroundColor: 'lightgray', borderRadius: '10px'}}>
                        The fastest way to help your customers - start chatting with visitors
                        The fastest way to help your customers - start chatting with visitors
                        who need your help using a free 30-day trial.
                    </MessageText>
                </Message>
                <Message date="21:38">
                    <MessageText style={{backgroundColor: 'lightgray', borderRadius: '10px'}}>Hi! I would like to buy those shoes</MessageText>
                </Message>
            </MessageGroup>
            <MessageGroup  onlyFirstWithMeta >
                <Message date="21:38" isOwn={true}>
                    <MessageText style={{border: 'solid', borderRadius: '10px', borderWidth: 'thin', borderColor: 'lightgrey'}}>
                        I love them much!
                    </MessageText>
                </Message>
                <Message date="21:38" isOwn={true}>
                    <MessageText style={{border: 'solid', borderRadius: '10px', borderWidth: 'thin', borderColor: 'lightgrey'}}>This helps me a lot</MessageText>
                </Message>
            </MessageGroup>
            <MessageGroup
                avatar="https://livechat.s3.amazonaws.com/default/avatars/male_8.jpg"
                onlyFirstWithMeta
            >
                <Message  date="21:37">
                    <MessageText style={{backgroundColor: 'lightgray', borderRadius: '10px'}}>No problem!</MessageText>
                </Message>
                <Message
                    imageUrl="https://static.staging.livechatinc.com/1520/P10B78E30V/dfd1830ebb68b4eefe6432d7ac2be2be/Cat-BusinessSidekick_Wallpapers.png"
                    date="21:39"
                >
                    <MessageText style={{backgroundColor: 'lightgray', borderRadius: '10px'}}>
                        The fastest way to help your customers - start chatting with visitors
                        who need your help using a free 30-day trial.
                    </MessageText>
                </Message>
            </MessageGroup>
            <MessageGroup  onlyFirstWithMeta >
                <Message date="21:38" isOwn={true}>
                    <MessageText style={{border: 'solid', borderRadius: '10px', borderWidth: 'thin', borderColor: 'lightgrey'}}>
                        Thank you for your help.
                    </MessageText>
                </Message>
                <Message date="21:38" isOwn={true}>
                    <MessageText style={{border: 'solid', borderRadius: '10px', borderWidth: 'thin', borderColor: 'lightgrey'}}>
                        I really appreciate that
                    </MessageText>

                </Message>
            </MessageGroup>

        </div>

        </MessageList>

<div>

        <TextComposer style={{bottom: 0, position:'fixed', width: '100%' }}>
            <Row style={{ display: 'flex' }}>

                <TextInput/>    <SendButton style={{marginRight: '2vh'}}/>



            </Row>

            <Row verticalAlign="bottom" justify="right">
            </Row>
        </TextComposer>


</div>





        </ThemeProvider>


        )

}


export default MessageConversation;

