import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ForumIcon from '@material-ui/icons/Forum';
import Badge from '@material-ui/core/Badge';

function BottomMenu() {

    const history = useHistory();

    const [value, setValue] = useState('home');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation value={value} onChange={handleChange} style={{
            width: '100%',
            backgroundColor: 'white',
            position: 'fixed',
            bottom: 0,
            left: 0,
            height: 60,
            zIndex: 5000,
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        }}>
            <BottomNavigationAction value="home"
                                    style={(value === "home") ? {color: '#D10019'} : {color: 'rgba(0, 0, 0, 0.54)'}}
                                    icon={<HomeIcon/>} onClick={() => {
                                        history.push("/");
            }}/>
            <BottomNavigationAction value="search"
                                    style={(value === "search") ? {color: '#D10019'} : {color: 'rgba(0, 0, 0, 0.54)'}}
                                    icon={<SearchIcon/>}/>

            <BottomNavigationAction value="create"
                                        style={(value === "create") ? {color: '#D10019'} : {color: 'rgba(0, 0, 0, 0.54)'}}
                                        icon={<AddCircleOutlineIcon/>} onClick={() => {
                                            history.push("/create");
            }}/>

            <BottomNavigationAction value="notifications"
                                    style={(value === "notifications") ? {color: '#D10019'} : {color: 'rgba(0, 0, 0, 0.54)'}}
                                    icon={<Badge variant="dot" color="secondary"><NotificationsNoneOutlinedIcon/></Badge>}/>
            <BottomNavigationAction value="messages"
                                    style={(value === "messages") ? {color: '#D10019'} : {color: 'rgba(0, 0, 0, 0.54)'}}
                                    icon={<ForumIcon/>} onClick={() => {
                                        history.push("/messages");
            }}/>
        </BottomNavigation>

    )
}

export default BottomMenu;