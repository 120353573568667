import React from 'react';
import {Card, CardContent, makeStyles} from "@material-ui/core";
import imgsTestes from "../../imgs/transferir.jpg";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowY: 'hidden',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

function FeedCommunity() {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Card className="feedCommunityCard">
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
            <Card className="feedCommunityCard">
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
            <Card className="feedCommunityCard">
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default FeedCommunity;